import axios from "axios";
import { logoutFun } from "redux/auth/action";
import { toast } from "react-toastify";
import factory from "../store/index";
const { store } = factory;
// const DB_URL = "https://thesai.co";
// const DB_URL = "https://mobeendeck.pythonanywhere.com"


const DB_URL = "https://api-eportal.1now.app";
// const DB_URL = "https://stage.backend.kbadosh.com:444";



const baseUrl = `${DB_URL}/api/`;

const customAxios = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
customAxios.interceptors.request.use(
  (config) => {
    let authen = store.getState()?.auth;
    if (authen.token) {
      config.headers["Authorization"] = `Bearer ${authen.token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    let authen = store.getState()?.auth;
    let data = { refresh: authen.refresh };
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      authen.refresh
    ) {
      store.dispatch(logoutFun(authen.refresh, true));
      toast.error("Session has been expired!");
    }
    // if (error.response.status === 500 && authen.refresh) {
    //   originalRequest._retry = true;
    //   axios
    //     .post(`${baseUrl}auth/token/refresh/`, data)
    //     .then((res) => {
    //       store.dispatch({
    //         type: "REFRESH_PROFILE",
    //         payload: res.data,
    //       });
    //       originalRequest.headers.Authorization = `Bearer ${res.data.access}`;
    //       return axios(originalRequest);
    //     })
    //     .catch((error) => {
    //       store.dispatch(logoutFun(authen.refresh));
    //       toast.error("Session has been expired!");
    //     });
    // }

    if (error?.response?.data?.detail === "User is inactive") {
      store.dispatch(logoutFun(authen.refresh, true));
    }
    return Promise.reject(error);
  }
);

export default customAxios;
